import { tryCatch } from '../catch'
import { contentfulClient } from '../contentful'

export class ContentfulService {
  static async getEntriesByType(contentTypeId: string, skip: number = 0, limit: number = 4): Promise<any> {
    try {
      const response = await contentfulClient.getEntries({
        content_type: contentTypeId,
        limit,
        skip
      })
      if (response) {
        return { items: response?.items || [], total: response?.total || 0 }
      }
    } catch (error) {
      tryCatch('ContentfulService.getEntrieByContentType', error)
    }
  }

  static async getEntryById(id: string): Promise<any> {
    try {
      const response = await contentfulClient.getEntry(id)
      if (response) {
        return response?.fields || []
      }
    } catch (error) {
      tryCatch('ContentfulService.getEntryById', error)
    }
  }
}
