import { ZodError } from 'zod'

import { notice } from '@/utils/addon'

export function tryCatch(message: string, error: any) {
  console.error(message, error)

  if (error?.response?.data) {
    let { status: statusCode, statusText } = error.response
    let content = error.response.data.message

    if (content instanceof Array) {
      content = content.map((r) => `<p>- ${r}</p>`).join('')
    } else {
      content = `<p>- ${content}</p>`
    }

    notice.error(content, { title: `${statusCode}: ${statusText || 'Unknown'}`, duration: 6 })
    return error.response.data.message
  } else {
    notice.error(error.message, { title: error.code, duration: 6 })
    return error.message
  }
}

export class ApiResponse {
  static json(data: any, statusCode: number = 200) {
    return Response.json(data, { status: statusCode })
  }

  static message(str: string, statusCode: number = 200) {
    return Response.json(
      {
        statusCode,
        message: str
      },
      {
        status: statusCode
      }
    )
  }

  static catch(error: any, statusCode: number = 422) {
    let message: string | string[] =
      error?.response?.data?.message || error?.message || error?.statusText || 'Something went wrong!'

    if (error instanceof ZodError && !error.isEmpty) {
      const { errors: err } = error as ZodError
      message = err.map((r) => `This field "${r.path[0]}": ${r.message.toLowerCase()}`)
    } else {
      if (typeof error === 'string') message = error
      if (error?.status) statusCode = error.status
      else if (error?.response?.data?.status) statusCode = error.response.data.status
    }

    console.error(error)

    return Response.json(
      {
        statusCode,
        message
      },
      {
        status: statusCode
      }
    )
  }
}
